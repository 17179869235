import { useQuery } from '@tanstack/react-query'

import { Choice, Choices, ChoicesPath } from 'types'
import get from 'common/utils/get'

export default function useChoices(): Choices
export default function useChoices(path: ChoicesPath): Choice[]
export default function useChoices(path?: ChoicesPath) {
  const { data } = useQuery({
    queryKey: ['choices'],
    staleTime: Infinity,
    select: (data) => {
      if (!path) {
        return data
      }

      return get<Choice[]>(data, path)
    },
  })

  return data
}

export function useChoice(
  path: ChoicesPath,
  value: string
): Choice | undefined {
  const choices = useChoices(path)
  return choices.find((choice) => choice.value === value)
}
