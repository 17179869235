import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import { TextField, TextFieldProps } from 'formik-mui'

type TextMaskCustomProps = {
  mask: string
  name: string
  onChange: (event: { target: { name: string; value: string } }) => void
}

const TextMaskCustom = forwardRef<HTMLInputElement, TextMaskCustomProps>(
  function TextMaskCustom(props, ref) {
    const { mask, onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask={mask}
        unmask
        inputRef={ref}
        onAccept={(value: string) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    )
  }
)

type MaskFieldProps = TextFieldProps & {
  mask: string
}

export default function MaskField({ mask, ...props }: MaskFieldProps) {
  return (
    <TextField
      {...props}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: TextMaskCustom as any,
        inputProps: { mask },
      }}
    />
  )
}
