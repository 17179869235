import { ReactNode, useState } from 'react'
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker'
import { FieldProps, getIn } from 'formik'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import dayjs, { Dayjs } from 'dayjs'

import { createErrorHandler } from './errorHandler'

// Copy of https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui-x-date-pickers/src/DatePicker.tsx
// but with updated props

export interface DatePickerProps
  extends FieldProps,
    Omit<MuiDatePickerProps<Dayjs>, 'name' | 'value' | 'error'> {
  placeholder?: string
  helperText?: ReactNode
  changeFormat?: string
}

function useDatePickerField({
  field: { onChange: _onChange, ...field },
  form: {
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  },
  disabled,
  format,
  changeFormat,
  label,
  placeholder,
  helperText,
  slots,
  slotProps,
  onChange,
  onError,
  ...props
}: DatePickerProps): MuiDatePickerProps<Dayjs> {
  const [open, setOpen] = useState(false)
  const fieldError = getIn(errors, field.name)
  const showError = getIn(touched, field.name) && !!fieldError

  return {
    open,
    disabled: disabled ?? isSubmitting,
    format: format || 'DD MMM, YYYY',
    slots: {
      openPickerIcon: CalendarTodayIcon,
      ...slots,
    },
    slotProps: {
      textField: {
        label,
        error: showError,
        helperText: showError ? fieldError : helperText,
        inputProps: { placeholder, readOnly: true },
        InputLabelProps: { shrink: true },
        onClick: () => setOpen(true),
        onBlur: () => setFieldTouched(field.name, true, true),
      },
      openPickerIcon: {
        color: 'action',
      },
      ...slotProps,
    },
    selectedSections: null,
    onChange:
      onChange ??
      ((date) => {
        // Do not switch this order, otherwise you might cause a race condition
        // See https://github.com/formium/formik/issues/2083#issuecomment-884831583
        setFieldTouched(field.name, true, false)
        setFieldValue(
          field.name,
          changeFormat ? date?.format(changeFormat) : date,
          true
        )
      }),
    onError:
      onError ?? createErrorHandler(fieldError, field.name, setFieldError),
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    ...field,
    ...props,
    value:
      field.value && typeof field.value === 'string'
        ? dayjs(field.value)
        : field.value,
  }
}

export default function DatePicker(props: DatePickerProps) {
  return <MuiDatePicker {...useDatePickerField(props)} />
}
