import { IMask } from 'react-imask'

import { NHS_MASK } from 'common/constants/common'

export default function formatNHS(nhs?: number): string {
  if (nhs === undefined) return ''

  const masked = IMask.createMask({
    mask: NHS_MASK,
  })
  masked.resolve(String(nhs))
  return masked.value
}

export function parseNHS(nhs?: string): number | undefined {
  if (nhs === undefined) return undefined
  const parsed = parseInt(nhs.replace(/\s/g, ''), 10)
  return isNaN(parsed) ? undefined : parsed
}
