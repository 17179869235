import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import SelectField, { OptionValue, SelectFieldProps } from '.'

type Props<Option extends Record<string, OptionValue>> =
  SelectFieldProps<Option> & {
    endpoint: string
    params?: object
    queryOptions?: UseQueryOptions<Option[]>
  }

export default function AsyncSelectField<
  Option extends Record<string, OptionValue>,
>({ endpoint, params = {}, queryOptions, field, ...props }: Props<Option>) {
  const { data: options, isLoading } = useQuery<Option[]>({
    queryKey: [endpoint, params],
    ...queryOptions,
  })

  return (
    <SelectField
      {...props}
      field={
        !isLoading
          ? field
          : {
              ...field,
              value: '',
            }
      }
      options={options || []}
    />
  )
}
